import React from 'react';
import Home from './home';

function index() {
  return (
    <Home />
  );
}

export default index;
